<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1340_5689)">
            <circle cx="10" cy="10" r="10" transform="rotate(-45 10 10)" fill="black" fill-opacity="0.2"/>
            <path d="M15.6564 9.99988H4.34266" stroke="white" stroke-linecap="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1340_5689">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "CalendarDayRemoveRangeIcon"
}
</script>

<style>

</style>
