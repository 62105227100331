<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1340_5684)">
            <circle cx="10" cy="10.0002" r="10" transform="rotate(-45 10 10.0002)" fill="#39C0ED"/>
            <path d="M9.99963 15.657V4.34327" stroke="white" stroke-linecap="round"/>
            <path d="M15.6564 10.0001H4.34266" stroke="white" stroke-linecap="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1340_5684">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "CalendarDayAddRangeIcon"
}
</script>

<style>

</style>
