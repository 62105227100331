<template>
  <div class="calendarPage__dayCalendarRow">
    <div class="calendarPage__dayCalendarRowTitle">
      <data-formatted date-format="EEEE, dd" :data="day" />
    </div>
    <div class="calendarPage__dayCalendarRowScrollingContainer">
      <div
        v-if="checkSunday(day)"
        class="calendarPage__dayCalendarRowScrolling"
      >
        <div
          v-for="(range, i) in rangeValue"
          class="calendarPage__dayCalendarRowItem"
        >
          <div class="calendarPage__dayCalendarRowTimeCounter">
            {{ toWords(i + 1) }} {{ $t("range") }}
          </div>
          <div class="d-flex align-items-center">
            <div class="calendarPage__dayCalendarRowTimeWrapper">
              <input
                v-model="rangeValue[i].start_time"
                @blur="addScheduleRange(rangeValue[i], i)"
                type="time"
              />
              to
              <input
                v-model="rangeValue[i].end_time"
                @blur="addScheduleRange(rangeValue[i], i)"
                type="time"
              />
            </div>
            <div class="cursor-pointer" @click="removeRangeElement(i)">
              <calendar-day-remove-range-icon />
            </div>
          </div>
        </div>
        <div class="calendarPage__dayCalendarRowEditBtn">
          <div @click="addRangeElement()">
            <calendar-day-add-range-icon />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataFormatted from "@/components/components-helpers/DataFormatted";
import CalendarDayRemoveRangeIcon from "@/components/UI/icons/addRemoveIcons/CalendarDayRemoveRangeIcon";
import CalendarDayAddRangeIcon from "@/components/UI/icons/addRemoveIcons/CalendarDayAddRangeIcon";
import { format, isSameDay, isSunday, set } from "date-fns";
import converter from "number-to-words";
import { useToast } from "vue-toastification";

export default {
  components: {
    CalendarDayAddRangeIcon,
    CalendarDayRemoveRangeIcon,
    DataFormatted,
  },
  props: {
    day: Date,
  },
  name: "DayCalendarRow",
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  data() {
    return {
      rangeValue: [
        {
          start_time: "",
          end_time: "",
        },
      ],
    };
  },
  methods: {
    addScheduleRange(value, index) {
      if (
        value.start_time.includes("AM") ||
        value.start_time.includes("PN") ||
        value.end_time.includes("AM") ||
        value.end_time.includes("PM")
      ) {
        value.start_time = this.convertTime12To24(value.start_time);
        value.end_time = this.convertTime12To24(value.end_time);
      }

      if (
        +value.start_time.slice(0, 2) < 8 ||
        (+value.end_time.slice(3) !== 0 && +value.end_time.slice(0, 2) >= 20)
      ) {
        this.toast.warning(this.$t("SchedulesIntervalToast"));
        return;
      }
      if (value.id) {
        this.patchSchedule(value);
        return;
      }
      if (value.start_time.length > 0 && value.end_time.length > 0) {
        let day = format(new Date(this.day), "yyyy-MM-dd");
        let hours_start = Number(value.start_time.substring(0, 2));
        let minute_start = Number(value.start_time.substring(3));
        let hours_end = Number(value.end_time.substring(0, 2));
        let minute_end = Number(value.end_time.substring(3));
        let start_time = set(new Date(this.day), {
          hours: hours_start,
          minutes: minute_start,
        });
        let end_time = set(new Date(this.day), {
          hours: hours_end,
          minutes: minute_end,
        });
        this.$http
          .post("schedules", {
            start_time: start_time,
            end_time: end_time,
            date: day,
          })
          .then(({ data }) => {
            this.rangeValue[index].id = data.body.id;
            this.toast.success(this.$t("successfulSaveData"));
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.toast.error(err.response.data.message);
            } else {
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
    patchSchedule(range) {
      let today = format(new Date(this.day), "yyyy-MM-dd");
      let hours_start = Number(range.start_time.substring(0, 2));
      let minute_start = Number(range.start_time.substring(3));
      let hours_end = Number(range.end_time.substring(0, 2));
      let minute_end = Number(range.end_time.substring(3));
      let start_time = set(new Date(this.day), {
        hours: hours_start,
        minutes: minute_start,
      });
      let end_time = set(new Date(this.day), {
        hours: hours_end,
        minutes: minute_end,
      });
      this.$http
        .patch(`schedules/${range.id}`, {
          start_time: start_time,
          end_time: end_time,
          date: today,
        })
        .then(() => {
          this.toast.success(this.$t("successfulSaveData"));
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    addRangeElement() {
      this.rangeValue.push({ start_time: "", end_time: "" });
    },
    removeRangeElement(index) {
      if (!!this.rangeValue[index].id) {
        this.$http.delete(`schedules/${this.rangeValue[index].id}`).then(() => {
          this.toast.success(this.$t("successfulSaveData"));
          if (this.rangeValue.length === 1) {
            this.rangeValue = [{ start_time: "", end_time: "" }];
          } else {
            this.rangeValue = this.rangeValue.filter(
              (item) => item.id !== this.rangeValue[index].id
            );
          }
        });
      } else {
        this.rangeValue = this.rangeValue.filter((item, i) => i !== index);
      }
    },
    toWords(value) {
      if (!value) return "";
      return converter.toWordsOrdinal(value);
    },
    checkSunday(day) {
      return !isSunday(day);
    },
    convertTime12To24(time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
  },
  computed: {
    scheduleValues() {
      return this.$store.getters.SCHEDULES_WORKING_HOURS;
    },
  },
  watch: {
    scheduleValues: {
      deep: true,
      handler(newValue) {
        if (newValue.length !== 0) {
          for (let i in newValue) {
            if (isSameDay(new Date(newValue[i].date), new Date(this.day))) {
              if (
                this.rangeValue[0].start_time.length === 0 &&
                this.rangeValue[0].end_time.length === 0
              ) {
                this.rangeValue[0] = newValue[i];
              } else {
                this.rangeValue.push(newValue[i]);
              }
            }
          }
          this.rangeValue = JSON.parse(JSON.stringify(this.rangeValue));
          this.rangeValue.forEach((element) => {
            if (element.start_time.length > 0 && element.end_time.length > 0) {
              element.start_time = format(
                new Date(element.start_time),
                "HH:mm"
              );
              element.end_time = format(new Date(element.end_time), "HH:mm");
            }
          });
        } else {
          this.rangeValue = [
            {
              start_time: "",
              end_time: "",
            },
          ];
        }
      },
    },
  },
};
</script>

<style></style>
