<template>
  <main-layout>
    <div class="calendarPage__titleContainer">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="calendarPage__title">
        {{ $t("workingHours") }}
      </h1>
    </div>
    <div class="calendarPage__dayCalendarHeaderWrapper">
      <div class="cursor-pointer" @click="goToPreviouslyWeek()">
        <arrow-left />
      </div>
      <div class="calendarPage__dayCalendarHeader">
        <span class="weight-600">
          <data-formatted date-format="dd" :data="weekdayInterval.start" />
        </span>
        <span v-if="isThisMonth" class="text-lowercase">
          <data-formatted date-format=" MMM" :data="weekdayInterval.start" />
        </span>
        -
        <span class="weight-600">
          <data-formatted date-format="dd" :data="weekdayInterval.end" />
        </span>
        <span class="text-lowercase">
          <data-formatted date-format=" MMM yyyy" :data="weekdayInterval.end" />
        </span>
      </div>
      <div class="cursor-pointer" @click="goToNexWeek()">
        <arrow-right />
      </div>
    </div>
    <div class="calendarPage__dayCalendarRowsWrapper">
      <div v-for="day in weekdayValues">
        <day-calendar-row :day="day" />
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import ArrowLeft from "@/components/UI/icons/arrows/ArrowLeft";
import ArrowRight from "@/components/UI/icons/arrows/ArrowRight";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import {
  add,
  eachDayOfInterval,
  endOfWeek,
  isSameMonth,
  startOfWeek,
} from "date-fns";
import DayCalendarRow from "@/components/elements/calendar/DayCalendarRow";

export default {
  name: "ExpertDayCalendarPage",
  components: {
    DayCalendarRow,
    DataFormatted,
    ArrowRight,
    ArrowLeft,
    HorizontalArrow,
    BaseButton,
    MainLayout,
  },
  data() {
    return {
      weekdayInterval: {
        start: startOfWeek(new Date(), { weekStartsOn: 1 }),
        end: endOfWeek(new Date(), { weekStartsOn: 1 }),
      },
    };
  },
  mounted() {
    this.getSchedules();
  },
  methods: {
    goToNexWeek() {
      this.weekdayInterval.start = add(new Date(this.weekdayInterval.start), {
        weeks: 1,
      });
      this.weekdayInterval.end = add(new Date(this.weekdayInterval.end), {
        weeks: 1,
      });
      this.getSchedules();
    },
    goToPreviouslyWeek() {
      this.weekdayInterval.start = add(new Date(this.weekdayInterval.start), {
        weeks: -1,
      });
      this.weekdayInterval.end = add(new Date(this.weekdayInterval.end), {
        weeks: -1,
      });
      this.getSchedules();
    },
    getSchedules() {
      this.$store.commit("SET_SCHEDULES_WORKING_HOURS", []);
      let start = new Date(this.weekdayInterval.start).setHours(0, 0);
      let end = new Date(this.weekdayInterval.end).setHours(23, 59);
      start = new Date(start).toISOString();
      end = new Date(end).toISOString();
      this.$http
        .get("schedules/start/" + start + "/end/" + end)
        .then(({ data }) => {
          this.$store.commit("SET_SCHEDULES_WORKING_HOURS", data.body);
        });
    },
  },
  computed: {
    isThisMonth() {
      return !isSameMonth(this.weekdayInterval.start, this.weekdayInterval.end);
    },
    weekdayValues() {
      return eachDayOfInterval({
        start: this.weekdayInterval.start,
        end: this.weekdayInterval.end,
      });
    },
  },
};
</script>

<style></style>
